// const baseURL = 'http://localhost:8080';

const config = {
    openAiApiKey: 'sk-ZkHbeLXdkzPCF1rLMY27VXNN29UEPg4J8GlQ-IZQqLT3BlbkFJGN44qiguVTKV_-dyE1Ss-JFe_JrRvW3AM5cXqb8A8A',
    nocodbApiUrl: 'https://app.nocodb.com/api/v2/tables/mlk7amufjw9q37c/records',
    nocodbApiToken: 'b8MCLBvZQld9trld8iiL0FBGaJK9uNaAhV8cyLoi',
    // videoRoomId: "long-5c7e6300-4f7f-4a62-86f4-6b4978a1e1a3",
    // SOCKET_SERVER_URL: 'https://serversocketio.ngrok.io',
    // // videoChatBaseUrl: 'https://officevideochat.ngrok.io', // Add this line
    // // videoChatBaseUrl: 'https://howard3000.ngrok.io', // Add this line
    // videoChatBaseUrl: 'https://office9000.dtix.tw', // Add this line
    // dtix_upload: 'https://media.dtix.tw/uploadPic/construction',
    // media2_upload: 'https://media2.dtix.tw/uploadPic/construction',
    // media2_younger_upload: 'https://media2.dtix.tw/uploadPic/younger',
    // tokenUrl: `${baseURL}/v1/oauth2/token`,
    // textToSpeechUrl: `${baseURL}/v1/task/text_to_speech`,
    // muteSpeak: `${baseURL}/v1/audio/volume/stream_music`,
    // servicesUrl: 'https://node1prod.dtix.tw/hotel',
    // streamUrl: 'https://hotel.datavansolutions.com:9092/stream_rags',
    // youngerBase: 'https://media2.frrut.net',
    // imageAi: 'https://node1prod.dtix.tw/younger',
    // aiconstruction: 'https://node1prod.dtix.tw/aiconstruction',
    // linenotify: 'https://node1prod.dtix.tw/videochat',


};

export default config;

