import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import startSound from './beep.mp3';
import sentSound from './sent2.mp3';
import axios from 'axios';
import config from './config';
import { FaTimes, FaSync } from 'react-icons/fa'; // Import X icon from FontAwesome
import moment from 'moment-timezone';

const silenceTimerDuration = 1400;

let isRecognitionRunning = false;

const Talk = () => {
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [silenceCountdown, setSilenceCountdown] = useState(silenceTimerDuration / 1000); // Convert ms to seconds for display
    const silenceTimeoutRef = useRef(null); // Ref to manage silence detection timeout
    const additionalSilenceTimeoutRef = useRef(null); // Ref for the additional 20 seconds timer
    const [currentEventAction, setCurrentEventAction] = useState('stop');
    const [intentionalStop, setIntentionalStop] = useState(false);
    const [countdownDisplay, setCountdownDisplay] = useState(null);
    const countdownIntervalRef = useRef(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [replyMessage, setReplyMessage] = useState('');
    const buttonRef = useRef(null);
    const [isFromQuestions, setIsFromQuestions] = useState(false);
    const [hasBeenClicked, setHasBeenClicked] = useState(false);
    const textareaRef = useRef(null);
    const [keyRefresh, setKeyRefresh] = useState(0);

    const talkVideoRef = useRef(null);
    const stopVideoRef = useRef(null);

    const [isIdle, setIsIdle] = useState(false);

    const [idleTime, setIdleTime] = useState(0); // State to track idle time
    // const idleTimeoutRef = useRef(null); // Ref to store the idle timeout function
    const idleThreshold = 20; // Idle time in seconds (200 seconds or 3 minutes 20 seconds)
    const [countdownBeforeReload, setCountdownBeforeReload] = useState(10); // Initialize countdown timer for reload

    // Define your preset questions
    // const questions = ["你們還有哪裡有分店?", "我的東西不見怎麼辦？", "麻將桌故障了", "我要玩桌遊但桌子太小", "店內可以吃東西或是抽菸嗎?"];

    const [questions, setQuestions] = useState([]); // State to hold 5 random questions


    const [faqData, setFaqData] = useState({}); // State to hold the question-answer pairs
    // const [loading, setLoading] = useState(false); // State to manage loading status

    const audioRef = useRef(null); // Ref to store the audio element
    const [ttsStatus, setTtsStatus] = useState('silence'); // Track the TTS status
    const dataFetchedRef = useRef(false);  // Track if the data is fetched to avoid fetching twice


    // State to hold the Taiwan time
    const [taiwanTime, setTaiwanTime] = useState(moment.tz('Asia/Taipei').format('HH:mm:ss'));

    // Update Taiwan time every second
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = moment.tz('Asia/Taipei');
            setTaiwanTime(currentTime.format('HH:mm:ss'));
        }, 1000); // Update every second
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    // Wrap the resetIdleTimer function in useCallback
    const resetIdleTimer = useCallback(() => {
        setIdleTime(0);
        setIsIdle(false);
    }, []); // No dependencies needed, function reference remains stable






    useEffect(() => {
        const audioElement = audioRef.current; // Get the current audio element
        const transcriptInputElement = textareaRef.current; // Get the current transcript input element

        // Reset idle if the audio is playing
        const resetIdleForAudio = () => {
            if (audioElement && !audioElement.paused) {
                resetIdleTimer(); // Reset idle timer when the audio is playing
            }
        };

        // Add event listeners to reset idle timer on user activity
        window.addEventListener('mousemove', resetIdleTimer);
        window.addEventListener('keypress', resetIdleTimer);
        window.addEventListener('touchstart', resetIdleTimer);
        window.addEventListener('click', resetIdleTimer);

        // Add event listeners for audio play and pause
        if (audioElement) {
            audioElement.addEventListener('play', resetIdleForAudio);   // Reset on audio play
            audioElement.addEventListener('pause', resetIdleForAudio);  // Reset on audio pause
        }

        // Add event listener for transcript input
        if (transcriptInputElement) {
            transcriptInputElement.addEventListener('input', resetIdleTimer); // Reset on text input
        }

        return () => {
            // Cleanup event listeners on component unmount
            window.removeEventListener('mousemove', resetIdleTimer);
            window.removeEventListener('keypress', resetIdleTimer);
            window.removeEventListener('touchstart', resetIdleTimer);
            window.removeEventListener('click', resetIdleTimer);

            // Cleanup audio event listeners
            if (audioElement) {
                audioElement.removeEventListener('play', resetIdleForAudio);
                audioElement.removeEventListener('pause', resetIdleForAudio);
            }

            // Cleanup transcript input listener
            if (transcriptInputElement) {
                transcriptInputElement.removeEventListener('input', resetIdleTimer);
            }
        };
    }, [resetIdleTimer]); // Keep the stable resetIdleTimer dependency



    // Function to handle closing and stopping all events
    const handleClose = () => {
        if (isRecognitionRunning && recognition) {
            recognition.stop(); // Stop speech recognition if it's running
            isRecognitionRunning = false;
        }
        if (audioRef.current) {
            audioRef.current.pause(); // Stop the audio playback
            audioRef.current = null;  // Clear the audio reference
        }
        clearTimeout(silenceTimeoutRef.current); // Clear the silence detection timeout
        clearTimeout(additionalSilenceTimeoutRef.current); // Clear the additional silence timeout
        clearInterval(countdownIntervalRef.current); // Clear any countdown intervals

        // Reset all states
        setIsListening(false);
        setIsButtonDisabled(false);
        setTranscript('');
        setReplyMessage('');
        setCountdownDisplay(null);

        // Set the video to "not talking"
        setCurrentEventAction('stop'); // Ensure the "not talking" video is playing

        console.log('All events stopped, video set to not talking, and reset');

        // Restart listening after 1 second
        setTimeout(() => {
            setIsListening(true);
            isRecognitionRunning = true;
            // Enable question buttons at the end
            setIsFromQuestions(false); // Enable the question buttons
            recognition.start();  // Restart speech recognition
            console.log('Listening restarted after 1 second');
        }, 1000); // Delay of 1 second (1000 ms)
    };


    // Fetch the question-answer data with pagination
    const fetchFaqData = async () => {
        try {
            let allData = [];
            let currentPage = 1;
            const pageSize = 50;

            let shouldFetchMore = true;

            while (shouldFetchMore) {
                const response = await axios.get(`${config.nocodbApiUrl}`, {
                    headers: {
                        'xc-token': config.nocodbApiToken,
                    },
                    params: {
                        page: currentPage,
                        limit: pageSize,
                    },
                });

                const rawData = response.data.list;

                if (rawData.length < pageSize) {
                    shouldFetchMore = false;
                }

                allData = [...allData, ...rawData];
                currentPage++;
            }

            const cleanedData = allData.map(({ Id, CreatedAt, UpdatedAt, ...rest }) => ({
                ...rest
            }));

            setFaqData(cleanedData);
            console.log('All Pages Fetched FAQ Data:', JSON.stringify(cleanedData));

        } catch (error) {
            console.error('Error fetching paginated data:', error);
        }
    };


    // Function to pick 5 random questions from faqData
    const pickRandomQuestions = useCallback(() => {
        if (faqData.length > 0) {
            const allQuestions = faqData.map(faq => faq.question); // Extract only the questions
            const shuffledQuestions = allQuestions.sort(() => 0.5 - Math.random()); // Shuffle the questions
            const selectedQuestions = shuffledQuestions.slice(0, 5); // Pick the first 5 questions
            setQuestions(selectedQuestions); // Set the 5 questions in state
        }
    }, [faqData]);



    useEffect(() => {

        // Set an interval to pick 5 random questions every 5 seconds
        const intervalId = setInterval(() => {
            pickRandomQuestions();
        }, 10000); // 5000ms = 5 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [pickRandomQuestions]);


    // Call pickRandomQuestions once when faqData is updated
    useEffect(() => {
        if (faqData.length > 0) {
            pickRandomQuestions(); // Call the function when faqData is updated
        }
    }, [faqData, pickRandomQuestions]); // This useEffect will trigger when faqData changes


    // Fetch data on component mount only once
    useEffect(() => {
        if (!dataFetchedRef.current) {
            fetchFaqData(); // Only fetch data once
            dataFetchedRef.current = true; // Mark the data as fetched
        }
    }, []); // Empty dependency array ensures this runs only once when the component mounts


    const clearAdditionalSilenceTimer = () => {
        if (additionalSilenceTimeoutRef.current) {
            clearTimeout(additionalSilenceTimeoutRef.current);
            additionalSilenceTimeoutRef.current = null;
        }
        if (countdownIntervalRef.current) {
            clearInterval(countdownIntervalRef.current);
            countdownIntervalRef.current = null;
        }
        setCountdownDisplay(null); // Reset the countdown display to hide it
        console.log("All timers reset");
    };


    // For the recognition-related callback, disable the warning
    const recognition = useMemo(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            const recognitionInstance = new SpeechRecognition();
            recognitionInstance.continuous = true;
            recognitionInstance.interimResults = true;
            recognitionInstance.lang = 'zh-TW';
            return recognitionInstance;
        }
        return null;
    }, []);  // Empty dependency array as recognition should only be initialized once



    // Memoize the handleIdleTimeout function using useCallback
    const handleIdleTimeout = useCallback(() => {
        if (hasBeenClicked) { // Only reload if hasBeenClicked is true

            setIsIdle(true); // Trigger idle state
        }
    }, [hasBeenClicked]); // Add recognition and hasBeenClicked as dependencies


    // Countdown for page reload
    useEffect(() => {
        if (isIdle) {
            if (countdownBeforeReload > 0) {
                const countdownInterval = setInterval(() => {
                    setCountdownBeforeReload((prev) => prev - 1);
                }, 1000);

                return () => clearInterval(countdownInterval); // Clean up the interval
            } else {
                // Reload the page when the countdown reaches 0
                window.location.reload(); // This ensures the page reloads when the countdown is finished
            }
        }
    }, [isIdle, countdownBeforeReload]);



    // Update the idle timer increment logic to account for the audio playing state
    useEffect(() => {
        // Function to increment idle time every second
        const incrementIdleTime = () => {
            if (audioRef.current && !audioRef.current.paused) {
                resetIdleTimer(); // Reset the idle timer if audio is playing
            } else {
                setIdleTime(prevTime => prevTime + 1); // Increment idle time if audio is not playing
            }

            // Trigger idle timeout when the threshold is reached and no audio is playing
            if (idleTime >= idleThreshold && (!audioRef.current || audioRef.current.paused)) {
                console.log('User has been idle for too long. Stopping recognition.');
                handleIdleTimeout(); // Custom function to handle timeout
            }
        };

        // Start the interval to track idle time
        const intervalId = setInterval(incrementIdleTime, 1000); // Increment every second

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [idleTime, handleIdleTimeout, resetIdleTimer]); // Ensure resetIdleTimer and handleIdleTimeout are stable

    // Function to handle text-to-speech
    const handleTextToSpeech = useCallback(async (text) => {
        try {
            // Stop and reset current audio if it's already playing
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                console.log('Existing audio stopped and reset.');
                audioRef.current = null;  // Clear previous reference
            }

            // Call API to get TTS audio
            const response = await axios.post(
                'https://api.openai.com/v1/audio/speech',
                {
                    model: 'tts-1',
                    input: text,
                    voice: 'nova',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${config.openAiApiKey}`,
                    },
                    responseType: 'blob', // Expect binary response (audio)
                }
            );

            // Create new audio element from response
            const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
            const audio = new Audio(audioUrl);
            audioRef.current = audio;

            // Debugging: Check if audio element is created
            console.log('New audio element created:', audio);

            // Set TTS status to "speaking" when audio starts
            audio.onplay = () => {
                setTtsStatus('speaking');
                console.log('TTS started speaking.');
            };

            // Set TTS status to "silence" when audio ends
            // Set TTS status to "silence" when audio ends
            audio.onended = () => {
                setTtsStatus('silence');
                audioRef.current = null;  // Clear the audio reference when finished
                console.log('TTS finished speaking and status set to "silence".');

                // Restart listening after TTS is done, if not already running
                if (!isRecognitionRunning) {
                    setIsListening(true);  // Set listening state back to true
                    setIsFromQuestions(false)
                    isRecognitionRunning = true;  // Mark recognition as running
                    recognition.start();  // Restart speech recognition
                    console.log('Restarting listening after TTS finished.');
                } else {
                    console.log('Speech recognition is already running.');
                }
            };



            // Play the audio
            audio.play().catch((err) => console.error('Error playing audio:', err));

            // Debugging: Confirm the play action is initiated
            console.log('Audio play action initiated.');
        } catch (error) {
            console.error('Error fetching or playing audio:', error);
        }
    }, [recognition]);

    const getHardwareInfo = async () => {
        const hardwareInfo = {};

        // Get device memory (if supported)
        if ('deviceMemory' in navigator) {
            hardwareInfo.deviceMemory = `${navigator.deviceMemory} GB`;
        }

        // Get hardware concurrency (number of logical processors)
        if ('hardwareConcurrency' in navigator) {
            hardwareInfo.hardwareConcurrency = navigator.hardwareConcurrency;
        }

        // Get touch support (check if the device has touch capabilities)
        hardwareInfo.touchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

        // Get color depth of the screen
        hardwareInfo.colorDepth = `${window.screen.colorDepth} bits`;

        // Get network information (if supported)
        if ('connection' in navigator) {
            const { downlink, effectiveType, rtt } = navigator.connection;
            hardwareInfo.networkInfo = {
                downlink: `${downlink} Mbps`,
                effectiveType,  // E.g., '4g', '3g', etc.
                rtt: `${rtt} ms`,  // Round-trip time
            };
        }

        // Get battery status (if supported)
        if ('getBattery' in navigator) {
            try {
                const battery = await navigator.getBattery();
                hardwareInfo.batteryStatus = {
                    level: `${battery.level * 100}%`,
                    charging: battery.charging,
                };
            } catch (error) {
                console.error('Error getting battery information:', error);
            }
        }

        return hardwareInfo;
    };

    const getBrowserInfo = () => {
        const { userAgent, platform, language } = navigator;
        const screenResolution = `${window.screen.width}x${window.screen.height}`;
        return {
            userAgent,
            platform,
            language,
            screenResolution,
        };
    };



    const sendFinalTranscript = useCallback(async (finalTranscript) => {
        const postToApi = async (question, answer, isData) => {
            try {
                const browserInfo = getBrowserInfo();
                const hardwareInfo = await getHardwareInfo();  // Now fetching hardware info asynchronously

                const payload = {
                    question,
                    answer,
                    isData,
                    browserInfo,
                    hardwareInfo,  // Include hardware info here
                };

                const response = await axios.post('https://node1prod.dtix.tw/ligo_input', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                console.log('API Response:', response.data);
            } catch (error) {
                console.error('Error posting to API:', error);
            }
        };

        try {
            // Prepare the updated messages with the final transcript as the user message
            const updatedMessages = [
                {
                    role: 'user',
                    content: finalTranscript
                }
            ];

            // const response = await axios.post(
            //     'https://api.openai.com/v1/chat/completions',
            //     {
            //         model: 'gpt-4o-mini',  // Assuming you're using this model
            //         messages: [
            //             {
            //                 role: 'system',
            //                 content: `你是一名麻將桌遊館助手叫Jenny，負責回答顧客的問題。你的任務是從已設定的 FAQ 數據中找出最相關的答案來回答顧客的問題。以下是店鋪的 FAQ 數據：
            //         ${faqData.map(faq => `問題: ${faq.question}, 答案: ${faq.answer}`).join('\n')}

            //         當顧客問問題時，請尋找最匹配的 FAQ 並以該答案回應。當找到合適的答案後，請使用以下的 JSON 格式回應：
            //         {
            //           "question": "顧客的問題",
            //           "answer": "對應的答案",
            //           "isData": true
            //         }
            //         如果找不到相關答案，請回應：
            //         {
            //           "question": "我們無法找到合適的答案",
            //           "answer": "很抱歉，我們無法在店中資料找到合適的答案。您可以透過line@ 聯繫喔! id為 @802oqhni ",
            //           "isData": false
            //         }`
            //             },
            //             ...updatedMessages
            //         ],
            //         response_format: {
            //             type: 'json_schema',
            //             json_schema: {
            //                 name: 'faq_response',
            //                 schema: {
            //                     type: 'object',
            //                     properties: {
            //                         question: { type: 'string' },
            //                         answer: { type: 'string' },
            //                         isData: { type: 'boolean' } // Add 'isData' as a boolean key
            //                     },
            //                     required: ['question', 'answer', 'isData'] // Require question, answer, and isData
            //                 }
            //             }
            //         }
            //     },
            //     {
            //         headers: {
            //             Authorization: `Bearer ${config.openAiApiKey}`,  // Replace with your API key
            //             'Content-Type': 'application/json'
            //         }
            //     }
            // );


            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-4o-mini',  // Assuming you're using this model
                    messages: [
                        {
                            role: 'system',
                            content: `你是一名麻將桌遊館助手叫Jenny，負責回答顧客的問題。你的任務是從已設定的 FAQ 數據中找出最相關的答案來回答顧客的問題。以下是店鋪的 FAQ 數據：
                        ${faqData.map(faq => `問題: ${faq.question}, 答案: ${faq.answer}`).join('\n')}
                        
                        當顧客問問題時，請尋找最匹配的 FAQ 並以該答案回應。當找到合適的答案後，請使用以下的 JSON 格式回應：
                        {
                          "question": "顧客的問題",
                          "answer": "對應的答案",
                          "isData": true
                        }
                        如果找不到相關答案，請基於問題生成一個最佳答案，並且請保持你的回答在100字以內，不超過100字。請使用以下的格式回應：
                        {
                          "question": "顧客的問題",
                          "answer": "GPT生成的答案",
                          "isData": false
                        }`
                        },
                        ...updatedMessages
                    ],
                    response_format: {
                        type: 'json_schema',
                        json_schema: {
                            name: 'faq_response',
                            schema: {
                                type: 'object',
                                properties: {
                                    question: { type: 'string' },
                                    answer: { type: 'string' },
                                    isData: { type: 'boolean' } // Add 'isData' as a boolean key
                                },
                                required: ['question', 'answer', 'isData'] // Require question, answer, and isData
                            }
                        }
                    }
                },
                {
                    headers: {
                        Authorization: `Bearer ${config.openAiApiKey}`,  // Replace with your API key
                        'Content-Type': 'application/json'
                    }
                }
            );



            // Handle the response
            const openAiResponse = response.data;
            if (openAiResponse.choices && openAiResponse.choices.length > 0) {
                const messageContent = openAiResponse.choices[0].message.content;

                // Parse the content to get the JSON response
                const parsedResponse = JSON.parse(messageContent);
                const { question, answer, isData } = parsedResponse;

                // Now use the extracted question, answer, and isData
                console.log(`Question: ${question}`);
                console.log(`Answer: ${answer}`);
                console.log(`Data found: ${isData}`);

                // Store the answer in the replyMessage state
                setReplyMessage(answer);

                // Call the text-to-speech function to play the audio
                handleTextToSpeech(answer); // Play the reply message as audio

                // You can now update your UI or handle the response further here

                // Post the data to the API
                postToApi(question, answer, isData);

            } else {
                console.error('No response from OpenAI API.');
            }

        } catch (error) {
            console.error('Error sending the transcript to OpenAI:', error);
        }
    }, [faqData, handleTextToSpeech]);







    useEffect(() => {
        if (!recognition) {
            console.log("Browser doesn't support Speech Recognition.");
            return;
        };

        recognition.onstart = () => {
            console.log('on start recognition');
            setIsListening(true);
            clearTimeout(silenceTimeoutRef.current);
            setTranscript('');
        };

        recognition.onresult = (event) => {
            clearTimeout(silenceTimeoutRef.current); // Clear existing timeout

            let currentTranscript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join(',');

            console.log(currentTranscript)

            setTranscript(currentTranscript);

            silenceTimeoutRef.current = setTimeout(() => {
                console.log('Silence detected, stopping recognition');

                // This is where you can handle the final transcript
                console.log("Final Transcript: ", currentTranscript); // This is the final transcript to send out


                setIsButtonDisabled(true);
                setIsListening(false); // Stop listening

                const audio = new Audio(sentSound);
                audio.play();

                // Now you can send out the final transcript
                sendFinalTranscript(currentTranscript); // Custom function to send the final transcript

            }, silenceTimerDuration);

            setSilenceCountdown(silenceTimerDuration / 1000); // Reset countdown
        };

        recognition.onend = () => {
            if (!intentionalStop && !isListening) {
                setIsListening(false);
                isRecognitionRunning = false;
                clearTimeout(silenceTimeoutRef.current);
                setIntentionalStop(false); // Reset the intentional stop flag
                console.log("Stopped recognition");
            } else {
                recognition.start(); // Restart if needed
            }
        };

        if (isListening && !isRecognitionRunning) {
            recognition.start();
            const audio = new Audio(startSound);
            audio.play();
            isRecognitionRunning = true;
        }

        return () => {
            if (isListening) {
                recognition.stop();
            }
        };
    }, [isListening, recognition, intentionalStop, sendFinalTranscript]);

    // Countdown logic
    useEffect(() => {
        const interval = setInterval(() => {
            setSilenceCountdown((prev) => prev <= 0 ? 0 : parseFloat((prev - 0.1).toFixed(1)));
        }, 100);
        return () => clearInterval(interval);
    }, []);


    // Modify renderLatestMessageByType to use replyMessage state
    const renderLatestMessageByType = () => {
        if (replyMessage) {
            return replyMessage;
        }
        return '請說話,我在聽...';
    };

    const publishMessage = useCallback((question, event) => {
        event.currentTarget.blur();
        setKeyRefresh(prev => prev + 1);
        clearAdditionalSilenceTimer();
        setCountdownDisplay(null);

        const audio = new Audio(sentSound);
        audio.play();
        setTranscript(question);
        setReplyMessage('');
        setIsFromQuestions(true);

        // Send the question to OpenAI using sendFinalTranscript
        sendFinalTranscript(question);
        console.log(question);

        setIsListening(false);
    }, [sendFinalTranscript]);


    const questionButtons = questions.map((question, index) => (
        <div
            key={`${index}-${keyRefresh}`}
            className={`border-solid border-3 border-violet-400 bg-violet-100 text-3xl rounded-xl text-violet-600 px-4 py-4 shadow-md ${isFromQuestions || !isListening ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(event) => {
                if (!isFromQuestions && isListening) {
                    publishMessage(question, event);
                }
            }}
            style={{ pointerEvents: isFromQuestions || !isListening ? 'none' : 'auto' }}
        >
            <h3 className='text-2xl font-bold leading-normal'>{question}</h3>
        </div>
    ));




    // Control the video based on TTS status
    useEffect(() => {
        if (ttsStatus === 'speaking') {
            setCurrentEventAction('talk'); // Show talking video
            setIsButtonDisabled(true); // Disable microphone button
        } else {
            setCurrentEventAction('stop'); // Show not talking video
            setIsButtonDisabled(false); // Enable microphone button
            // Add a 3-second delay before clearing the reply message
            const timeoutId = setTimeout(() => {
                setReplyMessage('');
            }, 3000); // 3000 ms = 3 seconds

            // Cleanup the timeout to avoid memory leaks if the component unmounts or ttsStatus changes
            return () => clearTimeout(timeoutId);
        }
    }, [ttsStatus]);

    // Video control
    useEffect(() => {
        const talkVideo = talkVideoRef.current;
        const stopVideo = stopVideoRef.current;

        if (currentEventAction === 'talk') {
            talkVideo.play();
            stopVideo.pause();
        } else {
            stopVideo.play();
            talkVideo.pause();
        }
    }, [currentEventAction]);

    // Video component
    const ControlVideoEvent = () => {
        const buffer = 0.5;
        const handleTimeUpdate = () => {
            const talkVideo = talkVideoRef.current;
            const stopVideo = stopVideoRef.current;
            if (talkVideo.currentTime > talkVideo.duration - buffer) {
                talkVideo.currentTime = 0;
                talkVideo.play();
            }
            if (stopVideo.currentTime > stopVideo.duration - buffer) {
                stopVideo.currentTime = 0;
                stopVideo.play();
            }
        };

        return (
            <div className="relative w-full text-center">
                <video
                    ref={talkVideoRef}
                    src="/ai_woman_talk_v1.webm"
                    className={`w-full ${currentEventAction === 'talk' ? 'opacity-100' : 'opacity-0'}`}
                    loop
                    muted
                    autoPlay
                    playsInline
                    preload="auto"
                    onTimeUpdate={handleTimeUpdate}
                />
                <video
                    ref={stopVideoRef}
                    src="/ai_woman_notTalk_v1.webm"
                    className={`w-full absolute top-0 ${currentEventAction === 'stop' ? 'opacity-100' : 'opacity-0'}`}
                    loop
                    muted
                    autoPlay
                    playsInline
                    preload="auto"
                    onTimeUpdate={handleTimeUpdate}
                />
            </div>
        );
    };



    // 滑動到最新的內容
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.scrollIntoView({ behavior: 'smooth' });
        };
    }, []);


    // Add a stop function that stops everything and reloads the page
    const handleStopAndReload = () => {
        // Stop recognition if running
        if (isRecognitionRunning && recognition) {
            recognition.stop();
            isRecognitionRunning = false;
        }

        // Stop the audio if playing
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current = null;
        }

        // Clear any timers or intervals
        clearTimeout(silenceTimeoutRef.current);
        clearTimeout(additionalSilenceTimeoutRef.current);
        clearInterval(countdownIntervalRef.current);

        // Reload the page
        window.location.reload();
    };

    return (
        <div>
            <div className="absolute top-10 left-10 z-20 w-auto">
                <img className="w-60" src="/logo.png" alt="123" />
            </div>

            {/* Display Taiwan time only */}
            <div style={{
                position: 'absolute',
                top: '20px',
                left: '50%', // Center horizontally
                transform: 'translateX(-50%)', // Adjust to center
                zIndex: 9999, // Ensure it's on top
                textAlign: 'center', // Center align the text
                fontWeight: 'bold', // Make the text bold
                color: '#fff', // Default text color
            }}>

                {/* Display the Taiwan time */}
                <div style={{
                    fontSize: '4rem', // Larger font for better readability
                    color: 'darkgrey', // Dark grey text for the time
                    marginBottom: '5px', // Space between time and message
                }}>
                    {taiwanTime}
                </div>
            </div>

            {hasBeenClicked && (
                <div className="absolute top-4 right-4 z-50">
                    <button
                        onClick={handleStopAndReload}
                        className="text-black bg-transparent p-8 rounded-full flex items-center justify-center"
                        style={{ fontSize: '2.5rem' }}  // Larger size
                    >
                        <FaTimes className="text-gray-600 mr-2" style={{}} /> {/* Apply scale transform for a thinner look */}
                        <span className="ml-2 text-gray-600">結束對話</span> {/* Add text here */}
                    </button>
                </div>
            )}



            {/* Other content */}
            {isIdle && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-[99999]">
                    {/* Highest z-index */}
                    <div className="bg-white p-10 rounded-lg text-center">
                        <h1 className="text-2xl font-bold">您已閒置過久，頁面將在 {countdownBeforeReload} 秒後重新載入。</h1>
                    </div>
                </div>
            )}

            {(!hasBeenClicked) ? (
                <div className="fixed top-0 left-2/4 translate-x-minus-half translate-y-double z-20 w-11/12">
                    <div className='absolute top-0 left-0 bg-violet-300/90 w-full h-32 rounded-t-3xl flex items-center justify-center'>
                        <h1 className='text-5xl font-bold text-violet-600'>歡迎光臨 粒古桌遊館</h1>
                    </div>
                    <div className='bg-slate-50/90 shadow-lg rounded-3xl px-5 pt-40 pb-10'>
                        <h1 className='w-full leading-loose text-zinc-600'>
                            ● 我們提供各式各樣的桌遊讓店內顧客使用。<br />
                            ● 請注意本店<span className='text-fuchsia-600 text-5xl font-semibold'>嚴禁賭博</span>和<span className='text-fuchsia-600 text-5xl font-semibold'>禁止任何現金交易</span>行為，如有發現將提供相關影片於警局備案。<br />
                            ● 在您離開時，請記得隨身物品是否已經帶走。
                        </h1>
                    </div>
                </div>
            ) : ""}

            {ControlVideoEvent()}{/* video */}
            <div>
                {/* Question Buttons Positioned Higher */}
                <div className='absolute top-1/2 left-1/2 translate-x-minus-half translate-y-minus-half flex flex-wrap justify-center w-full gap-3 z-30'>
                    {hasBeenClicked && questionButtons}
                </div>


                {
                    hasBeenClicked && (
                        <div className="fixed w-4/5" style={{ top: '60%', left: '10%' }}>
                            {/* Close Button with Refresh Icon and Larger Text */}
                            <div className="absolute" style={{ top: '20px', right: '20px', zIndex: 50 }}>
                                <button
                                    onClick={handleClose}
                                    className="text-white bg-gray-600 rounded-full p-4 flex items-center justify-center" // Flex for icon and text alignment
                                >
                                    <FaSync className="text-3xl" /> {/* Font Awesome Refresh Icon */}
                                    <span className="ml-2 text-white text-2xl">重講</span> {/* Larger text */}
                                </button>
                            </div>



                            <div className="h-auto border-5 border-purple-300 bg-slate-50/90 shadow-lg rounded-3xl px-8 py-8 overflow-auto z-[50]">
                                <h6 className="text-gray-600"> {silenceCountdown.toFixed(1)} 秒後送出</h6>

                                {/* Larger Text Input Fields */}
                                <div>
                                    <h2
                                        className="text-black font-bold text-left leading-normal flex-1"
                                        style={{ fontSize: '2rem', lineHeight: '2.5rem', padding: '16px', minHeight: '100px' }}
                                    >
                                        {transcript}
                                    </h2> {/* Explicitly Larger Question Text */}

                                    <h2
                                        className="text-blue-500 font-bold text-left leading-normal flex-1"
                                        style={{ fontSize: '2rem', lineHeight: '2.5rem', padding: '16px', minHeight: '100px' }}
                                    >
                                        {renderLatestMessageByType("reply")}
                                    </h2> {/* Explicitly Larger Reply Text */}
                                    <div ref={textareaRef} />
                                </div>
                            </div>
                        </div>
                    )
                }


                {/* Microphone Button */}
                <div className="absolute left-1/2 translate-x-minus-half z-30 text-xl bg-white text-violet-600 w-52 h-52 rounded-full shadow-shadow-inner-purple"
                    style={{ top: '84%' }}> {/* Use percentage to position it higher */}
                    {!isListening ? (
                        <div
                            ref={buttonRef}
                            onClick={() => {
                                if (!isButtonDisabled && !isFromQuestions) {
                                    setIsButtonDisabled(true);
                                    setIsListening(false);
                                    setHasBeenClicked(true);
                                    setTimeout(() => {
                                        setIsListening(true);

                                    }, 100);
                                }
                            }}
                            className="flex justify-center items-center h-52"
                        >
                            {isButtonDisabled ? (
                                <FaMicrophoneSlash className="text-8xl" style={{ color: 'grey' }} />
                            ) : (
                                <FaMicrophone className="text-8xl" />
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className={`bg-gradient-to-l from-[#cd7cff] to-[#25d7ff] w-52 h-52 rounded-[100px] ${isListening ? 'animate-spin' : ''}`}></div>
                        </div>
                    )}
                </div>


                {/* Countdown Display */}
                <div className='absolute bottom-2.5 left-2/4 translate-x-minus-half z-10'>
                    {countdownDisplay !== null && (
                        <div className='text-grey size-7'>
                            {countdownDisplay}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default Talk;
